import type { Pharmacy, PharmacySearchResult } from '~/generated/api-clients-generated'
import { PharmacyStore } from '~/stores/pharmacy'

export class PharmacyService {
  static #pharmacyCache: Record<string, PharmacySearchResult> = {}

  static use() {
    const mailOrderPharmacy: Pharmacy = {
      id: 'mail-order',
      npi: 'mail-order',
      name: 'Mail Order Pharmacy'
    }

    async function autocompletePharmacy(
      query: string,
      zip: string | null,
      radius?: number | undefined,
      take?: number | undefined
    ): Promise<PharmacySearchResult[]> {
      const cleanQuery = query.trim().toLowerCase()
      const pharmacyStore = PharmacyStore.use()

      const client = PxApi.use().createPharmacyClient()

      const searchResults = await client.search({
        take: take ?? 50,
        skip: 0,
        zip: zip,
        radius: radius ?? 50,
        pharmacyName: cleanQuery
      })

      if (searchResults?.length < 1) {
        return []
      }

      // Limits results to 10
      // Filters out any pharmacies that are already added to profile
      return (
        searchResults
          ?.slice(0, searchResults.length > 10 ? 10 : searchResults.length)
          ?.filter((x) => !pharmacyStore?.pharmacies?.map((x) => x.npi)?.includes(x.npi)) ?? []
      )
    }

    async function fetchPharmacy(npi: string) {
      const client = PxApi.use().createPharmacyClient()

      if (!PharmacyService.#pharmacyCache[npi]) {
        PharmacyService.#pharmacyCache[npi] = await client.findByNpi(npi)
      }

      return PharmacyService.#pharmacyCache[npi]
    }

    function mapPharmacyFromSearchResult(
      pharmacy: PharmacySearchResult,
      preferred: boolean = true
    ) {
      return {
        externalId: pharmacy!.id,
        isPreferred: preferred,
        ...pharmacy
      }
    }

    function formatPharmacyAddress(
      pharmacy: Pharmacy | PharmacySearchResult,
      includeStreet: boolean = true
    ) {
      if (!pharmacy.address1) return ''

      const address = {
        line1: pharmacy.address1!,
        line2: pharmacy.address2!,
        city: pharmacy.city!,
        state: pharmacy.state!,
        zip: pharmacy.zip!
      }

      let fmtAddress = ''
      if (includeStreet) {
        fmtAddress += formatStreet(address)
      }

      fmtAddress += formatCityStateZip(address)

      return fmtAddress
    }

    return {
      mailOrderPharmacy,
      autocompletePharmacy,
      fetchPharmacy,
      mapPharmacyFromSearchResult,
      formatPharmacyAddress
    }
  }

  static readonly #injectionKeys = {}

  static provide() {}

  static inject = () => ({})
}
