import { SessionStore } from '~/stores/session'
import { usePageLeave, whenever } from '@vueuse/core'

export type ExitIntentContext = 'global' | 'application'

export class ExitIntent {
  static async use(context: ExitIntentContext, dialogId: string) {

    const { hide } = DialogController.use()
    const { track } = Analytics.use()
    const { getComponentContent } = Cms.use()

    const { content } = await getComponentContent(`ExitIntent${_startCase(context)}`)

    const options = computed(() => Object.keys(content.value.ext)
      .filter(k => k.endsWith('option'))
      .map(k => ({
        value: k,
        text: content.value.ext[k]
      }))
    )

    const selections = ref<string[]>([])
    const feedback = ref<string | null>(null)

    const { getBaseTrackingData } = ExitIntent.useTrackingData(context)

    const onViewed = () => {
      track('ExitSurveyView', {
        ...getBaseTrackingData(),
        content: options.value
      })
    }

    const onSubmit = () => {
      track('ExitSurveyResponse', {
        ...getBaseTrackingData(),
        content: options.value,
        selections: selections.value,
        openFeedback: feedback.value,
      })

      hide(dialogId)
    }

    return {
      onViewed,
      onSubmit,
      options,
      selections,
      feedback,
      content
    }
  }

  static useTriggers(context: ExitIntentContext, dialogId: string) {
    const session = SessionStore.use()
    const { flag } = FeatureFlags.use()
    const { show } = DialogController.use()
    const { isMobile } = Breakpoints.use()
    const hasLeft = usePageLeave()

    const enabledContexts = computed(() => flag<ExitIntentContext[]>('exit-intent').value)
    const shouldSurvey = computed(() => enabledContexts.value.includes(context) && !session.exitIntentSurveyed.includes(context))

    const onExitIntent = () => {
      if(shouldSurvey.value) {
        show(dialogId)
        session.exitIntentSurveyed.push(context)
      }
    }

    if(!isMobile.value) {
      whenever(hasLeft, () => {
        onExitIntent()
      })
    }

    return {
      shouldSurvey,
      onExitIntent,
    }
  }

  static useTrackingData(context: ExitIntentContext) {
    const { isMobile } = Breakpoints.use()

    const getBaseTrackingData = () => ({
      trigger: isMobile ? 'back' : 'mouse',
      exitSurveyType: context === 'application' ? 'application' : 'quotes',
    })

    return {
      getBaseTrackingData
    }
  }
}