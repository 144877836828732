import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { PlanCheckupStore } from '~/stores/planCheckup'
import { EngineStore } from '~/stores/engine'
import { PlanType } from '~/generated/api-clients-generated'
import { SessionStore } from '~/stores/session'

export default class CheckupAepPostRunNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const checkupStore = PlanCheckupStore.use()
    const engine = EngineStore.use()
    const session = SessionStore.use()
    const { sortBy } = QuoteUtils.useSort()

    session.planType = checkupStore.currentCheckup?.desiredPlanType ?? PlanType.MAPD

    const applyCarrierKey = !_isNil(checkupStore.currentCheckup?.loyaltyCarrierKey)
      && engine.availableQuotes.some(q =>
        q.details.carrierKey === checkupStore.currentCheckup!.loyaltyCarrierKey)

    const candidatePlans = applyCarrierKey
      ? engine.availableQuotes.filter(q => q.details.carrierKey === checkupStore.currentCheckup!.loyaltyCarrierKey)
      : engine.availableQuotes

    const bestPlan = candidatePlans
      .find(q => q.planAttributes.includes('bestPlan'))
      //@ts-ignore
      ?? sortBy(candidatePlans, 'default')[0]

    await checkupStore.updateCheckup({
      engineId: engine.id,
      engineParams: engine.params,
      bestPlanId: bestPlan?.medicareId
    })
  }
}