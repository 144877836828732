import { EngineStore } from '~/stores/engine'
import { SessionStore } from '~/stores/session'
import { QuoteUtils, valuesForHumans } from '~/composables/QuoteUtils'
import { PlanType } from '~/generated/api-clients-generated'
import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import { PharmacyCoverageLevel } from '~/models/quoting/PharmacyCoverage'
import { now } from '~/utils/dates'
import { ProfileStore } from '~/stores/profile'

export class QuoteAnalytics {
  static use() {
    const { track } = Analytics.use()

    const _debouncedTrack = _debounce(track, 1000)

    function quotesLoaded() {
      const engine = EngineStore.use()
      _debouncedTrack('QuotesLoaded', {
        ...getEngineEventProps(),
        orderedPlanIds: engine.displayedQuotes.map((x) => x.medicareId)
      })
    }

    function planFinderEngineParams() {
      _debouncedTrack('PlanFinderEngineParams', {
        ...getEngineEventProps()
      })
    }

    function planTypeSelected(planType: PlanType) {
      const session = SessionStore.use()

      _debouncedTrack('PlanTypeSelected', {
        profileId: session.profileId,
        planType: PlanType[planType]
      })
    }

    function getEngineEventProps() {
      const engine = EngineStore.use()
      const session = SessionStore.use()
      const profile = ProfileStore.use()
      const { defaultSort } = QuoteUtils.useSort()

      return {
        profileId: session.profileId,
        email: profile.emailAddress?.address,
        mobilePhone: profile.mobilePhone?.number,
        engineId: engine.id,
        timestamp: now().format(),
        zip: engine.params.zip,
        fips: engine.params.county,
        state: engine.params.state,
        planYear: engine.params.planYear,
        planType: PlanType[engine.selectedPlanType!],
        filters: valuesForHumans(session.quoteFilters),
        sort: session.quoteSortOrder === 'default' ? defaultSort.value : session.quoteSortOrder,
        compare: session.comparedPlans,
        rxs: engine.params?.rxs?.map((x) => x.uuid) ?? [],
        doctors: engine.params?.doctors?.map((x) => x.uuid) ?? [],
        pharmacy: engine.params?.pharmacy?.uuid ?? null,
        benefits: engine.params?.additionalBenefits?.map((x) => x),
        planList: session.planList,
        tagCatalog: engine.displayedQuotes?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.medicareId]: curr.planAttributes
          }),
          {}
        )
      }
    }

    function getQuotePageEventProps(elementKey: string) {
      return {
        key: elementKey,
        ...getEngineEventProps()
      }
    }

    function getQuoteCardEventProps(elementKey: string, quote: QuotedPlanVm) {
      const engine = EngineStore.use()

      return {
        key: elementKey,
        ...getEngineEventProps(),
        medicareId: quote.medicareId,
        score: quote.score,
        rank: engine.displayedQuotes.findIndex((x) => x.medicareId === quote.medicareId),
        rxsCovered: quote.drugCoverage.coverageSummaries
          .filter((x) => x.isCovered)
          .map((x) => x.uuid),
        doctorsCovered: quote.providerCoverage.providers
          .filter((x) => x.inNetwork)
          .map((x) => x.uuid),
        pharmacyStatus:
          quote.pharmacyCoverage.pharmacies.length > 0
            ? PharmacyCoverageLevel[quote.pharmacyCoverage.coverageLevel]
            : null,
        benefits: quote.additionalBenefits.additionalBenefits
          .filter((x) => x.covered)
          .map((x) => x.name)
      }
    }

    return {
      quotesLoaded,
      planTypeSelected,
      getQuoteCardEventProps,
      getQuotePageEventProps,
      getEngineEventProps
    }
  }
}
