import { SiteStore } from '~/stores/site'
import { StoreInit } from '~/composables/StoreInit'
import { SessionStore } from '~/stores/session'
import { CarrierStore } from '~/stores/carriers'
import { FeatureFlags } from '~/composables/FeatureFlags'

export class AppInit {
  static async use() {
    const site = SiteStore.use()
    const { journeyId, route } = AppRoute.use()
    const config = useRuntimeConfig()
    const cookieJourneyId = useCookie(`${config.public.cookieEnvPrefix}journey_id`)

    const { flag } = FeatureFlags.use()
    const siteConfig = flag<any>('site-config').value

    let activeJourneyId = 0
    if (!_isNil(journeyId.value) && siteConfig.active_journey_ids.includes(journeyId.value)) {
      activeJourneyId = journeyId.value
    } else if (cookieJourneyId.value) {
      const jid = parseInt(cookieJourneyId.value)

      if (siteConfig.active_journey_ids.includes(jid)) {
        activeJourneyId = jid
      } else {
        activeJourneyId = siteConfig.default_journey_id
      }
    } else {
      activeJourneyId = flag('journey-id', siteConfig.default_journey_id).value
    }

    cookieJourneyId.value = activeJourneyId.toString()

    site.load(activeJourneyId)

    await StoreInit.use()

    const session = SessionStore.use()

    if (!_isEmpty(route.query)) {
      session.params = _mapValues(
        {
          ...session.params,
          ...route.query
        },
        (x) => (_isArray(x) ? _first(x) : _isObject(x) ? null : x)
      )
    }

    session.zipCode ??= session.params.zip_code ?? null

    if (!('journey-id' in session.flags)) {
      session.captureFlag('journey_id', activeJourneyId)
    }
  }
}
