import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'

export default class IsPreshopNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    return this.yesNo(flag<boolean>('is-pre-shopping').value)
  }
}