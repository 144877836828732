<template>
  <client-only>
    <Teleport to="body">
      <TalkdeskChatWidget
        :context="context"
        :hide-launcher="hideLauncher"
        :show-notifications="true"
        :launcher-position-class="launcherClasses"
        :window-bottom="!isDesktop ? 0 : 80"
        :disable="!actionsEnabled"
        :use-native-launcher="launcherVariation === 'talkdesk' && isDesktop"
      />
    </Teleport>
  </client-only>
</template>

<script lang="ts" setup>
  import { ProfileStore } from '~/stores/profile'
  import { PlanType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'

  const profile = ProfileStore.use()
  const engine = EngineStore.use()

  const { actionsEnabled } = ActionGuard.use()

  const context = computed(() => ({
    profile_id: profile.id,
    td_contact_person_identification: profile.emailAddress.address ?? profile.mobilePhone.number
  }))

  const { breakpoints } = Breakpoints.use()
  const isDesktop = computed(() => breakpoints.greaterOrEqual('md').value)

  const launcherClasses = computed(() => ['bottom-3', 'right-[20px]', 'z-[50]'])

  const { flag } = FeatureFlags.use()
  const launcherVariation = flag('chat-launcher-variation').value

  const { path } = AppRoute.use()

  const stickyQuoteVariation = flag<any>('quote-page-sticky-actions').value

  const hideLauncherOnQuotes = computed(
    () =>
      path.value == '/quotes' &&
      stickyQuoteVariation?.position == 'bottom' &&
      engine.selectedPlanType !== PlanType.GAP
  )

  const hideLauncher = computed(() => !isDesktop.value || hideLauncherOnQuotes.value)
</script>

<style scoped></style>
