import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import { PharmacyStore } from '~/stores/pharmacy'

export type PharmacyChainTokenConfig = {
  token: string
  name: string
  key: string
  search_value: string
  data_consent: boolean
}

export class PharmacyCampaigns {
  static async run() {
    const session = SessionStore.use()

    if (!!session.associatedPharmacy?.npi) return

    const { tryRunTdsCampaign, tryRunPharmacyChainCampaign } = this.use()

    ;(await tryRunPharmacyChainCampaign()) || (await tryRunTdsCampaign())
  }

  static use() {
    const session = SessionStore.use()
    const profile = ProfileStore.use()
    const pharmacyStore = PharmacyStore.use()

    const { fetchPharmacy, autocompletePharmacy, mapPharmacyFromSearchResult, mailOrderPharmacy } =
      PharmacyService.use()

    const { flag } = FeatureFlags.use()

    /* Pharmacy Chain - Set to the closest pharmacy
     *  Query Param: ?pharmacy=publix
     * */
    async function tryRunPharmacyChainCampaign() {
      try {
        const chainToken = session.params.pharmacy
        const ldConfigs = flag<PharmacyChainTokenConfig[]>('pharmacy-chain-tokens')
        const config = ldConfigs.value.find((x) => x.key === chainToken)

        if (!chainToken) {
          console.log('No pharmacy chain token found in query params')
          return false
        }

        if (!config) {
          console.error('Failed to find LD config for token: ', chainToken.value)
          return false
        }

        if (config.search_value === 'MAIL_ORDER') {
          console.log('adding mail order pharmacy')
          await pharmacyStore.save(mailOrderPharmacy)
          session.associatedPharmacy = mailOrderPharmacy
          return true
        }

        console.log('searching pharmacies by: ', config.search_value)
        const searchResults = await autocompletePharmacy(
          config.search_value,
          profile.location.zipCode!,
          50,
          50
        )

        console.log('Pharmacy chain search results: ', searchResults)

        if (searchResults.length > 0) {
          const result = searchResults[0]

          const pharmacy = mapPharmacyFromSearchResult(result, true)
          pharmacy.name = config.name
          await pharmacyStore.save(pharmacy)

          session.associatedPharmacy = pharmacy

          return true
        }

        return false
      } catch (err) {
        console.error('Unhandled error when setting pharmacy association: ', err)
        return false
      }
    }

    /* TDS - Pharmacy Association by NPI
     * Query Params: ?npi=123&pharmacyassociation=true
     * */
    async function tryRunTdsCampaign() {
      const isTds = !!session.params.pharmacyassociation
      const npi = session.params.npi

      try {
        if (!isTds) {
          console.log('No pharmacy association found in query params')
          return false
        }

        const result = await fetchPharmacy(npi)

        if (!result?.name) {
          console.error('Failed to find pharmacy by npi: ', npi)
          return false
        }

        const pharmacy = mapPharmacyFromSearchResult(result, true)

        await pharmacyStore.save(pharmacy)

        session.associatedPharmacy = pharmacy
        return true
      } catch (err) {
        console.error('Unhandled error when setting pharmacy association')
        return false
      }
    }

    return {
      tryRunPharmacyChainCampaign,
      tryRunTdsCampaign
    }
  }
}
