import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'

export default class HasPendingDrugsOrDoctorsNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { isProviderDiff, isDrugsDiff } = QuoteChangeMonitor.use()

    console.log('HasPendingDrugsOrDoctorsNode: ', isProviderDiff.value, isDrugsDiff.value)

    return this.yesNo(isProviderDiff.value || isDrugsDiff.value)
  }
}
