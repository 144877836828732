import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { PlanCheckupStore } from '~/stores/planCheckup'
import { ProfileStore } from '~/stores/profile'
import { SessionStore } from '~/stores/session'
import dayjs from 'dayjs'

export class CreateCheckupNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const checkupStore = PlanCheckupStore.use()
    const profile = ProfileStore.use()
    const session = SessionStore.use()

    if (_isNil(checkupStore.currentCheckup)) {
      await checkupStore.createCheckup()
      await profile.load(profile.id!)
    }

    session.planYear = dayjs().year()
  }
}
