import { AdditionalBenefitType, SnpType } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'
import { ProviderStore } from '~/stores/provider'
import { RxStore } from '~/stores/rx'
import { PharmacyStore } from '~/stores/pharmacy'
import { EngineStore } from '~/stores/engine'

export class QuoteChangeMonitor {
  static use() {
    const profile = ProfileStore.use()
    const providerStore = ProviderStore.use()
    const rxStore = RxStore.use()
    const pharmacyStore = PharmacyStore.use()
    const engine = EngineStore.use()

    const isProviderDiff = computed<boolean>(() => {
      const profileProviderNpis: Array<string | undefined> = []
      for (let providerIndex in providerStore.providers) {
        for (let location in providerStore.providers[providerIndex].locations) {
          profileProviderNpis.push(providerStore.providers[providerIndex].npi)
        }
      }
      const recentEngineProviders =
        engine.params.doctors?.map((engineDoctor) => engineDoctor.npi)?.sort() ?? []

      return !_isEqual(profileProviderNpis.sort(), recentEngineProviders)
    })

    const isDrugsDiff = computed<boolean>(() => {
      const profileDrugs =
        rxStore.rxs?.map((rx) => `${rx.dosage?.ndc}${rx.frequency?.toString()}`)?.sort() ?? []
      const recentEngineRxs =
        engine.params.rxs?.map((rx) => `${rx.ndc}${rx.frequency?.toString()}`)?.sort() ?? []

      return !_isEqual(profileDrugs, recentEngineRxs)
    })

    const isUtilizationDiff = computed<boolean>(() => {
      const profileUtil =
        profile.needsAssessment.healthcareUtilization
          ?.map((u) => `${u.questionId}:${u.answer}`)
          ?.sort() ?? []
      const recentEngineUtil =
        engine.params.surveyAnswers?.map((u) => `${u.questionId}:${u.answer}`)?.sort() ?? []

      return !_isEqual(profileUtil, recentEngineUtil)
    })

    const isPharmDiff = computed<boolean>(() => {
      const prefPharmacyNpi =
        pharmacyStore.pharmacies.find((x) => !!x.isPreferred)?.npi ?? undefined
      const recentEnginePharmNpi = engine.params.pharmacy?.npi ?? undefined
      return prefPharmacyNpi !== recentEnginePharmNpi
    })

    const isBenefitsDiff = computed<boolean>(() => {
      const profileBenefits =
        profile.needsAssessment?.additionalBenefits?.filter(
          (benefit) => benefit !== AdditionalBenefitType.None
        ) ?? []
      const engineBenefits = engine.params.additionalBenefits ?? []
      return !_isEqual(profileBenefits, engineBenefits)
    })

    const isMedicaidDiff = computed<boolean>(() => {
      const engineHasMedicaid = engine.params?.snpTypes?.includes(SnpType.DualEligible) ?? false
      return engineHasMedicaid !== profile.medicaidInfo.hasMedicaid
    })

    const isInsuranceDiff = computed<boolean>(() => {
      return (
        isProviderDiff.value ||
        isDrugsDiff.value ||
        isPharmDiff.value ||
        isBenefitsDiff.value ||
        isUtilizationDiff.value ||
        isMedicaidDiff.value
      )
    })

    return {
      isInsuranceDiff,
      isDrugsDiff,
      isProviderDiff,
      isPharmDiff,
      isBenefitsDiff,
      isUtilizationDiff,
      isMedicaidDiff
    }
  }
}
