import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { EngineStore } from '~/stores/engine'
import { PlanCheckupStore } from '~/stores/planCheckup'
import { ProfileStore } from '~/stores/profile'

export class SetCheckupEngineNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const engine = EngineStore.use()
    const checkupStore = PlanCheckupStore.use()
    const profile = ProfileStore.use()

    await profile.setEngineId(checkupStore.currentCheckup!.engineId!)
    await engine.load(checkupStore.currentCheckup!.engineId!)
  }
}
