import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'

export class NoAccountEnrollNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const noAccountApps = computed(() => flag<boolean>('no-account-enroll').value)

    return this.yesNo(noAccountApps.value)
  }
}