import dayjs from 'dayjs'

import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import RelativeTime from 'dayjs/plugin/relativeTime'
import UTC from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(CustomParseFormat)
dayjs.extend(AdvancedFormat)
dayjs.extend(isBetween)
dayjs.extend(RelativeTime)
dayjs.extend(UTC)
dayjs.extend(tz)

export const now = () => dayjs().utc()

export const nowTz = (tzVal) => dayjs().tz(tzVal)

export const parseDate = (value) => {
  if (!value) return dayjs('invalid')
  if (typeof value !== 'string') return dayjs(value)
  if (value.endsWith('Z')) return dayjs(value)
  return dayjs(
    value,
    ['YYYY-MM-DD', 'MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ssZ'],
    false
  )
}

export const formatDateAs = (value, format) => _format(value, format)
export const formatDate = (value) => _format(value, 'MM/DD/YYYY')
export const formatDateNthDay = (value) => _format(value, 'MMMM Do, YYYY')

export const formatDateTime = (value) => _format(value, 'MM/DD/YYYY hh:mm:ss A')

export const formatIso = (value) => _format(value, 'YYYY-MM-DD')

export const formatTimestamp = (value) => _format(value, 'x')

export const dateFromString = (str) => {
  switch (str) {
    case 'today':
      return dayjs().startOf('day')
    case 'tomorrow':
      return dayjs().add(1, 'day').startOf('day')
    case 'yesterday':
      return dayjs().add(-1, 'day').startOf('day')
    default:
      return parseDate(str)
  }
}

export const age = (birthDate, asOf = null) => {
  let start = parseInt(_format(birthDate, 'YYYYMMDD'))
  let end = parseInt(_format(asOf || dayjs(), 'YYYYMMDD'))
  return Math.trunc((end - start) / 10000)
}

const _format = (value, format) => {
  if (!value) return ''

  let date = dateFromString(value)
  if (!date.isValid()) return ''

  return date.format(format)
}

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const jan1 = (year) => dayjs.utc(parseDate(`${year}-01-01`))
