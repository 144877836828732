import { useTimeoutFn } from '@vueuse/shared'
import { SessionStore } from '~/stores/session'
import { EngineStore } from '~/stores/engine'
import { useIdle } from '@vueuse/core'

export class ProactiveChat {
  static useChatTrigger() {
    const session = SessionStore.use()
    const { show } = ChatController.use()
    const { path } = AppRoute.use()

    const { breakpoints } = Breakpoints.use()
    const canBeProactive = computed(() => breakpoints.greaterOrEqual('md').value)

    const wasAlreadyProactive = (path: string) => {
      if (path.includes('apply')) return session.wasProactiveOnApp
      return session.wasProactive
    }

    const setWasProactive = (path: string) => {
      if (path.includes('apply')) session.wasProactiveOnApp = true
      else session.wasProactive = true
    }

    const triggerProactiveChat = () => {
      if (!canBeProactive.value) return
      if (wasAlreadyProactive(path.value)) return
      setWasProactive(path.value)
      show('talkdesk')
    }

    return {
      triggerProactiveChat
    }
  }

  static use() {
    const { path } = AppRoute.use()
    const { isAuthenticated } = Auth.use()
    const session = SessionStore.use()
    const engine = EngineStore.use()
    const { triggerProactiveChat } = ProactiveChat.useChatTrigger()
    const { flag } = FeatureFlags.use()

    const configs = flag('proactive-chat-config', [])

    const getConfigs = (path: string, type: 'timeout' | 'idle') =>
      configs.value.filter(
        (x) =>
          x.paths.some((p) => path.startsWith(normalizePath(p))) &&
          x.type === type &&
          (x.is_authenticated === isAuthenticated.value || _isNil(x.is_authenticated))
      )

    const checkCondition = (condition: string | null | undefined) => {
      switch (condition) {
        case 'has_needs':
          return !engine.isEmptyState
        case 'back_from_app':
          return ['/apply', '/account/sign-up?next=/apply-router'].includes(session.prevPage)
        default:
          return true
      }
    }

    const timeoutConfigs = getConfigs(path.value, 'timeout')

    for (const config of timeoutConfigs) {
      useTimeoutFn(
        () => {
          if (checkCondition(config.condition)) {
            triggerProactiveChat()
          }
        },
        config.seconds * 1000,
        { immediate: true }
      )
    }

    const idleConfigs = getConfigs(path.value, 'idle')

    for (const config of idleConfigs) {
      const { idle } = useIdle(config.seconds * 1000, { events: ['keydown', 'mousedown'] })

      watch(idle, (val) => {
        if (val && checkCondition(config.condition)) {
          triggerProactiveChat()
        }
      })
    }
  }
}
