import { defineStore } from 'pinia'
import type { Guid } from '~/types/util'
import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import { SessionStore } from '~/stores/session'
import type {
  EngineParams,
  EngineSnapshot,
  PlanQuote,
  ProviderCoverageDetail
} from '~/generated/api-clients-generated'
import { AdditionalBenefitType, Gender, PlanType, SnpType } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'
import { DefaultDates } from '~/composables/DefaultDates'
import type { QuoteFilterName } from '~/composables/QuoteUtils'
import { QuoteFilters, QuoteUtils } from '~/composables/QuoteUtils'
import { RxStore } from '~/stores/rx'
import { PharmacyStore } from '~/stores/pharmacy'
import { ProviderStore } from '~/stores/provider'
import type { StoreSyncData } from '~/composables/TabSync'
import { PharmacyCoverageLevel } from '~/models/quoting/PharmacyCoverage'
import { PlanCheckupStore } from '~/stores/planCheckup'
import { ProactiveChat } from '~/composables/ProactiveChat'
import { useTimeoutFn } from '@vueuse/shared'

// const engineStorageKey = (id: Guid) => `engine-${id}`
const ENGINE_STORAGE_KEY = 'engine-current'

export class EngineStore {
  static use = defineStore('engine', () => {
    const initialized = ref<boolean>(false)

    const id = ref<Guid | null>(null)
    const params = ref<EngineParams>({} as EngineParams)
    const quotes = ref<Array<QuotedPlanVm>>([])
    const quotesCatalog = ref<Record<string, QuotedPlanVm>>({})

    function $reset() {
      id.value = null
      params.value = {}
      quotes.value = []
      quotesCatalog.value = {}
      initialized.value = false

      EngineStore.deleteSnapshot()
    }

    const session = SessionStore.use()

    const validPlanTypes: Array<PlanType | null | undefined> = [
      PlanType.MAPD,
      PlanType.PDP,
      PlanType.GAP
    ]

    const selectedPlanType = computed(() =>
      validPlanTypes.includes(session.planType) ? session.planType : PlanType.MAPD
    )

    const pendingPlanType = ref<PlanType | null>(null)

    const availablePlanTypes = computed(() => {
      if (!_isEmpty(session.availablePlanTypes)) return session.availablePlanTypes
      return params.value.planTypes
    })

    const previewFilters = ref<QuoteFilters>(QuoteFilters.empty(session.planYear))

    function initFilters() {
      previewFilters.value = { ...session.quoteFilters }
    }

    function applyFilters() {
      session.quoteFilters = { ...previewFilters.value }
      session.planYear = previewFilters.value.planYear
    }

    function clearFilters() {
      previewFilters.value = QuoteFilters.empty(session.planYear)
    }

    const availableQuotes = computed(() =>
      quotes.value.filter((x) => x.type === selectedPlanType.value)
    )

    const availableCarriers = computed(() =>
      _uniq(availableQuotes.value?.map((x) => x.details?.carrierFilterKey))
    )

    const getFilterPreview = (skip: Array<QuoteFilterName> = []) => {
      const { filter } = QuoteUtils.useFilters(previewFilters.value)
      // @ts-ignore
      return filter(availableQuotes.value, skip)
    }

    const filteredQuotes = computed(() => {
      const { filter } = QuoteUtils.useFilters(session.quoteFilters)
      // @ts-ignore
      return filter(availableQuotes.value)
    })

    const { sortBy } = QuoteUtils.useSort()
    const displayedQuotes = computed(() => {
      // @ts-ignore
      return sortBy(filteredQuotes.value, session.quoteSortOrder)
    })

    const isEmptyState = computed(() => {
      if (_isEmpty(params.value)) return true

      const items = [
        params.value.rxs,
        params.value.additionalBenefits,
        params.value.doctors,
        params.value.surveyAnswers
      ].reduce((acc, ls) => acc + (ls?.length ?? 0), 0)

      return items === 0
    })

    const noCoveredDoctors = computed(
      () =>
        params.value.doctors?.length > 0 &&
        availableQuotes.value.every((x) => x.providerCoverage.inNetworkCount === 0)
    )

    const noCoveredDrugs = computed(
      () =>
        params.value.rxs.length > 0 &&
        availableQuotes.value.every((x) => x.drugCoverage.coveredCount === 0)
    )

    const carrierQuotes = computed(
      (): Record<string, QuotedPlanVm[]> =>
        quotes.value.reduce((acc: any, current: any) => {
          const key = current.details.carrierKey!
          acc[key]?.length ? acc[key].push(current) : (acc[key] = [current])
          return acc
        }, {})
    )

    function init(state: EngineSnapshot) {
      const s = EngineStore.materializeSnapshot(state)
      const { $root } = useNuxtApp()

      id.value = s.id
      params.value = s.params
      quotes.value = s.quotes
      quotesCatalog.value = s.quotesCatalog

      initialized.value = true

      $root.emit('engine-ready')
    }

    const client = computed(() => {
      const { createQuotingClient } = PxApi.use()
      return createQuotingClient()
    })

    async function load(engineId: string, cache: boolean = true) {
      if (id.value !== engineId) {
        id.value = engineId

        const data = EngineStore.retrieveSnapshot()
        if (!!data) {
          init(data as EngineSnapshot)
        }

        const state = await client.value.getEngine(engineId)

        init(state)

        if (cache) {
          await EngineStore.cacheSnapshot(state)
        }
      }
    }

    return {
      initialized,
      id,
      params,
      quotes,
      quotesCatalog,
      availableQuotes,
      availableCarriers,
      displayedQuotes,
      carrierQuotes,
      load,
      init,
      initFilters,
      applyFilters,
      clearFilters,
      quoteFilters: previewFilters,
      getFilterPreview,
      selectedPlanType,
      pendingPlanType,
      availablePlanTypes,
      isEmptyState,
      validPlanTypes,
      noCoveredDoctors,
      noCoveredDrugs,
      $reset
    }
  })

  private static cacheSnapshot(state: EngineSnapshot) {
    if (process.client) {
      try {
        localStorage.setItem(ENGINE_STORAGE_KEY, JSON.stringify(state))
      } catch (ex) {
        // TODO Sentry
        console.log('EngineStore cache snapshot', ex)
      }
    }
  }

  public static retrieveSnapshot(): EngineSnapshot | null {
    if (process.client) {
      try {
        const json = localStorage.getItem(ENGINE_STORAGE_KEY)

        if (!!json) {
          const data = JSON.parse(json)
          if (!!data) return data
          else localStorage.removeItem(ENGINE_STORAGE_KEY)
        }
      } catch (ex) {
        // TODO Sentry
        console.log('EngineStore load', ex)
      }
    }

    return null
  }

  public static deleteSnapshot() {
    if (process.client) {
      localStorage.removeItem(ENGINE_STORAGE_KEY)
    }
  }

  public static materializeSnapshot(state: EngineSnapshot) {
    function _consolidateQuoteProviders(quote: PlanQuote) {
      let newProviders: ProviderCoverageDetail[] = []
      const uniqueNpis = [...new Set(quote.providerCoverage?.providers?.map((x) => x.npi) ?? [])]
      uniqueNpis.forEach((x) =>
        newProviders.push(
          quote.providerCoverage!.providers!.find((y) => y.npi == x && !!y.inNetwork) ??
            quote.providerCoverage!.providers!.find((y) => y.npi == x && !y.inNetwork) ??
            {}
        )
      )
      quote.providerCoverage!.providers = newProviders
      return quote
    }

    const quotes =
      state.quotes?.map(
        (x) =>
          new QuotedPlanVm(
            _consolidateQuoteProviders(x),
            state.plans!.find((y) => y.medicareId === x.medicareId)!
          )
      ) ?? []

    return {
      id: state.id!,
      params: state.params!,
      quotes: quotes,
      quotesCatalog: _keyBy(quotes, (x) => x.medicareId) ?? {}
    }
  }

  private static abortController: AbortController | null = null

  static async run(planTypes: Array<PlanType> = []) {
    if (!!this.abortController) {
      this.abortController.abort()
    }

    this.abortController = new AbortController()

    const engine = EngineStore.use()
    const session = SessionStore.use()

    const defaultPlanTypes: PlanType[] = [PlanType.MAPD, PlanType.PDP]
    planTypes = planTypes.filter(
      (x) => engine.validPlanTypes.includes(x) && session.availablePlanTypes.includes(x)
    )

    if (_isEmpty(planTypes)) {
      //@ts-ignore
      planTypes = _isNil(engine.pendingPlanType ?? session.planType)
        ? defaultPlanTypes
        : [engine.pendingPlanType ?? session.planType]
    }

    const params = this.getParams(planTypes)

    const { createQuotingClient } = PxApi.use()
    const client = createQuotingClient()

    let snapshot = null

    try {
      const enginePromise = client.runEngine(
        {
          id: engine.id,
          params
        },
        this.abortController.signal
      )

      snapshot = await enginePromise
    } finally {
      this.abortController = null
    }

    EngineStore.cacheSnapshot(snapshot)
    engine.init(snapshot)

    if (!_isNil(engine.pendingPlanType)) {
      session.planType = engine.pendingPlanType
      engine.pendingPlanType = null
    }

    if (!_isNil(session.associatedPharmacy)) {
      session.planList = 'preferredPharmacy'
      session.quoteFilters.pharmacyNetwork = PharmacyCoverageLevel.Preferred
    } else {
      session.planList = 'all'
    }

    const profile = ProfileStore.use()
    profile.setEngineId(snapshot!.id!)

    if (process.client) {
      const { push } = Gtm.use()
      push('Quoted')

      const { quotesLoaded } = QuoteAnalytics.use()
      quotesLoaded()

      if (engine.noCoveredDoctors || engine.noCoveredDrugs) {
        const { triggerProactiveChat } = ProactiveChat.useChatTrigger()
        useTimeoutFn(triggerProactiveChat, 3000)
      }
    }
  }

  static getParams(planTypes: Array<PlanType>): EngineParams {
    const rxStore = RxStore.use()
    const pharmacyStore = PharmacyStore.use()
    const providerStore = ProviderStore.use()
    const profile = ProfileStore.use()
    const session = SessionStore.use()
    const checkups = PlanCheckupStore.use()

    const { startDate, startYear, startMonth, planYear, dateOfBirth } = DefaultDates.use(
      session.planYear!
    )

    const { flag } = FeatureFlags.use()
    const isAep = flag('is-aep', false)
    const noPlanData = flag('no-plan-data', false)

    return {
      planYear: planYear.value,
      zip: profile.location!.zipCode!,
      county: profile.location!.fipsCountyCode!,
      countyName: profile.location!.countyName ?? undefined,
      state: profile.location!.stateCode!,
      rxs: rxStore.rxs.map((x) => ({
        qty: x.quantity,
        uuid: x.uuid,
        metricQty: _isEmpty(x.dosage!.package)
          ? x.quantity
          : x.quantity! * (x.dosage!.package!.packageSize! * x.dosage!.package!.packageQuantity!),
        frequency: x.frequency,
        ndc: x.dosage!.ndc ?? undefined,
        name: x.drugName,
        pkg: _isEmpty(x.dosage!.package)
          ? null
          : {
              ndc: x.dosage!.package!.ndc
            }
      })),
      doctors: providerStore.providers.flatMap((x) =>
        x.locations!.map((l) => ({
          locationExternalId: l.externalId!,
          npi: x.npi,
          uuid: x.uuid,
          name: x.displayName,
          specialty: x.specialty
        }))
      ),
      pharmacy:
        pharmacyStore.pharmacies
          .filter((x) => x.isPreferred)
          .map((x) => ({
            npi: x.npi!,
            uuid: x.uuid,
            name: x.name,
            isMailOrder: x.npi == 'mail-order'
          }))[0] ?? null,
      startDate: startDate.value.toDate(),
      startMonth: startMonth.value,
      startYear: startYear.value,
      dateOfBirth: profile.dateOfBirth
        ? parseDate(profile.dateOfBirth).toDate()
        : dateOfBirth.value.toDate(),
      gender: profile.gender === Gender.Female ? 'female' : 'male',
      surveyAnswers:
        profile.needsAssessment.healthcareUtilization?.map((x) => ({
          questionId: x.questionId!,
          answer: x.answer!
        })) ?? [],
      additionalBenefits: profile
        .needsAssessment!.additionalBenefits!.filter((x) => x !== AdditionalBenefitType.None)
        .map((x) => x as number),
      remoteConfigKey: session.params.token ?? null,
      partAEffectiveDate: null,
      partBEffectiveDate: null,
      isTobaccoUser: profile.isTobaccoUser,
      currentPlanId: checkups.currentCheckup?.currentPlanId,
      planTypes: planTypes,
      // carrierFootprint: session.carrierFootprint,
      snpTypes: profile.medicaidInfo?.hasMedicaid ? [SnpType.DualEligible] : [],
      overrides: ([] as string[])
        .concat(isAep.value ? ['aep'] : [])
        .concat(noPlanData.value ? ['no-plan-data'] : [])
    }
  }

  static get syncSerializer() {
    return {
      serialize: (data: StoreSyncData) => {
        console.log('SERIALIZE ENGINE')
        return JSON.stringify({ source: data.source, state: {} })
      },
      deserialize: (json: string) => {
        const syncData = JSON.parse(json) as StoreSyncData
        const snapshot = EngineStore.retrieveSnapshot()

        const state = !!snapshot
          ? EngineStore.materializeSnapshot(snapshot)
          : {
              id: null,
              params: {} as EngineParams,
              quotes: [],
              quotesCatalog: {} as Record<string, QuotedPlanVm>
            }

        console.log('RESTORE ENGINE')

        return { source: syncData.source, state: state }
      }
    }
  }

  static reset() {
    const engine = EngineStore.use()
    engine.$reset()
  }
}
