import type { ProviderCoverage, ProviderCoverageDetail } from '~/generated/api-clients-generated'

export class ProviderCoverageVm {
  readonly providers: Array<ProviderCoverageDetail>

  constructor(raw?: ProviderCoverage) {
    this.providers = raw?.providers || []
  }

  get count() {
    return this.providers?.length ?? 0
  }

  get inNetwork() {
    return this.providers.filter((x) => x.inNetwork) ?? []
  }

  get inNetworkCount() {
    return this.inNetwork.length
  }

  get inNetworkPercentage() {
    return (this.inNetworkCount / this.count) * 100
  }

  get allInNetwork() {
    return this.count === this.inNetworkCount
  }
}
