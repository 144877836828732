import { defineStore } from 'pinia'
import { type ConvoSchema, ConvoSubsectionSchema } from '~/models/conversation/SchemaModels'

export class ConversationStore {
  static use = defineStore('conversation', () => {
    const loading = ref<boolean>(false)

    const currentSchema = ref<ConvoSchema>()

    const currentSectionId = ref<string | null>(null)

    async function loadSchema(schema: ConvoSchema) {
      console.log('LOAD SCHEMA: ', schema.id)
      currentSchema.value = schema
      currentSectionId.value = currentSchema.value?.id
    }

    // recursively finds a section by id and resets it
    function resetSection(sectionId: string, subsection?: ConvoSubsectionSchema) {
      const section = subsection ?? currentSchema.value!

      if (section.id == sectionId) {
        section.activePrompt = null
        section.showAllPrompts = false
        return true
      }

      if (section.prompts) {
        for (const p of section.prompts) {
          if (p.subsection) {
            if (resetSection(sectionId, p.subsection)) {
              return true
            }
          }
        }
      }
    }

    return {
      loading,
      currentSchema,
      currentSectionId,
      resetSection,
      loadSchema
    }
  })

  static async init(schema: ConvoSchema) {
    const store = ConversationStore.use()
    await store.loadSchema(schema)
  }
}
