import dayjs from 'dayjs'

export class DefaultDates {
  static use(year?: number) {
    const startDate = computed(() => {
      if (!year || year === now().year()) {
        return now().add(1, 'month').startOf('month')
      }

      return jan1(year)
    })
    const startYear = computed(() => startDate.value.year())
    const startMonth = computed(() => startDate.value.month() + 1)
    const dateOfBirth = computed(() => startDate.value.subtract(65, 'year'))
    const planYear = computed(() => startDate.value.year())

    return {
      startDate,
      startYear,
      startMonth,
      dateOfBirth,
      planYear
    }
  }
}
