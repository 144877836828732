import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import {
  BenefitCategory,
  CostFactor,
  PlanBenefitCostType,
  PlanBenefitType,
  PlanType,
  PolicyType,
  SnpType
} from '~/generated/api-clients-generated'
import { PharmacyCoverageLevel } from '~/models/quoting/PharmacyCoverage'
import dayjs from 'dayjs'
import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import type { PlanList } from '~/composables/PlanLists'

export type PlanHighlightFilter =
  | 'zeroPremium'
  | 'zeroDeductible'
  | 'coversAllDocs'
  | 'fiveStarPlans'
  | 'ppoPlans'
  | 'preferredPharma'
  | 'dsnp'
  | 'partBGiveback'
  | 'telehealth'
  | 'mailOrder'

export type QuoteFilters = {
  planHighlights: Array<PlanHighlightFilter>
  planList: PlanList | null
  carriers: Array<string>
  policyTypes: Array<PolicyType>
  benefits: Array<PlanBenefitType>
  rxs: Array<string>
  providers: Array<string>
  pharmacyNetwork: PharmacyCoverageLevel | null
  planYear: number | null
}

export const QuoteFilters = {
  empty: (planYear: number | null) => {
    return {
      planHighlights: [],
      planList: null,
      carriers: [],
      policyTypes: [],
      benefits: [],
      rxs: [],
      providers: [],
      pharmacyNetwork: null,
      planYear: planYear
    } as QuoteFilters
  }
}

type QuoteSortConfig = { [Property in keyof QuoteSortOrder]: any }

export function valuesForHumans(filters: QuoteFilters) {
  return {
    carriers: [...filters.carriers],
    policyTypes: filters.policyTypes.map((t) => PolicyType[t]),
    benefits: filters.benefits.map((b) => PlanBenefitType[b]),
    // rxs: [...filters.rxs],
    // providers: [...filters.providers],
    // pharmacyNetwork: _isNil(filters.pharmacyNetwork)
    //   ? null
    //   : PharmacyCoverageLevel[filters.pharmacyNetwork],
    planYear: filters.planYear
  }
}

export type QuoteFilterName = keyof QuoteFilters

export const QUOTE_SORT_ORDERS = [
  'default',
  'premium',
  'oop',
  'deductible',
  'docs_then_est_costs',
  'healthpilot_rec',
  'total_yearly_costs'
] as const

export type QuoteSortOrder = (typeof QUOTE_SORT_ORDERS)[number]

export type QuoteSortTracker = {
  [PlanType.MAPD]: QuoteSortOrder
  [PlanType.PDP]: QuoteSortOrder
  [PlanType.GAP]: QuoteSortOrder

  // shut up typescript
  [PlanType.MA]: QuoteSortOrder
  [PlanType.MSA]: QuoteSortOrder
  [PlanType.TM]: QuoteSortOrder
}

export const QuoteSortTracker = {
  default() {
    return {
      [PlanType.MAPD]: 'default' as QuoteSortOrder,
      [PlanType.PDP]: 'default' as QuoteSortOrder,
      [PlanType.GAP]: 'default' as QuoteSortOrder,

      [PlanType.MA]: 'default' as QuoteSortOrder,
      [PlanType.MSA]: 'default' as QuoteSortOrder,
      [PlanType.TM]: 'default' as QuoteSortOrder
    }
  }
}

const sortsByPlanType = {
  [PlanType.MAPD]: [
    'default',
    'healthpilot_rec',
    'total_yearly_costs',
    'docs_then_est_costs',
    'premium',
    'oop',
    'deductible'
  ],
  [PlanType.PDP]: ['default', 'total_yearly_costs', 'premium', 'deductible'],
  [PlanType.GAP]: ['default', 'premium'],
  [PlanType.MA]: [],
  [PlanType.MSA]: [],
  [PlanType.TM]: []
}

export const planHighlightFilters: Record<PlanHighlightFilter, (quote: QuotedPlanVm) => boolean> = {
  coversAllDocs(quote: QuotedPlanVm): boolean {
    return quote.providerCoverage.count > 0 && quote.providerCoverage.allInNetwork
  },
  dsnp(quote: QuotedPlanVm): boolean {
    return quote.snpType === SnpType.DualEligible
  },
  fiveStarPlans(quote: QuotedPlanVm): boolean {
    return quote.details.starRating === 5
  },
  ppoPlans(quote: QuotedPlanVm): boolean {
    return [PolicyType.PPO, PolicyType.LPPO, PolicyType.RPPO].includes(quote.policyType)
  },
  preferredPharma(quote: QuotedPlanVm): boolean {
    return !!quote.pharmacyCoverage.first && (quote.pharmacyCoverage.preferred ?? false)
  },
  zeroPremium(quote: QuotedPlanVm): boolean {
    return quote.details.premium! <= 0
  },
  zeroDeductible(quote: QuotedPlanVm): boolean {
    return quote.details.medicalDeductible! <= 0
  },
  partBGiveback(quote: QuotedPlanVm): boolean {
    const ben = _flatMap(quote.details.curatedBenefits, (cb) => cb.benefits).find(
      (b) => b?.categoryId === BenefitCategory.PartBGiveback
    )

    return !!ben && !!ben.description && !ben.description.includes('does not offer') //conecture :rolleyes:
  },
  telehealth(quote: QuotedPlanVm): boolean {
    return !!_flatMap(quote.details.curatedBenefits, (cb) => cb.benefits).find(
      (b) => b?.categoryId === BenefitCategory.AdditionalTelehealth
    )
  },
  mailOrder(quote: QuotedPlanVm): boolean {
    const mailPricing = _flatMap(quote.details.formulary?.tiers, (t) => [
      t.ninetyDayPricing,
      t.sixtyDayPricing,
      t.thirtyDayPricing
    ])

    return mailPricing.some((mp) => !_isNil(mp?.preferredMail) || !_isNil(mp?.standardMail))
  }
}

export class QuoteUtils {
  static useFilters(filters: QuoteFilters) {
    function filter(
      plans: Array<QuotedPlanVm>,
      skip: Array<QuoteFilterName> = []
    ): Array<QuotedPlanVm> {
      let filtered = [...plans]

      if (!skip.includes('planList')) {
        filtered = byPlanList(filtered)
      }

      if (!skip.includes('carriers')) {
        filtered = byCarrier(filtered)
      }

      if (!skip.includes('benefits')) {
        filtered = byBenefits(filtered)
      }

      if (!skip.includes('providers')) {
        filtered = byProviders(filtered)
      }

      if (!skip.includes('rxs')) {
        filtered = byRxs(filtered)
      }

      if (!skip.includes('pharmacyNetwork')) {
        filtered = byPharmacyNetwork(filtered)
      }

      if (!skip.includes('policyTypes')) {
        filtered = byPolicyTypes(filtered)
      }

      if (!skip.includes('planHighlights')) {
        filtered = byPlanHighlight(filtered)
      }

      return filtered
    }

    function byPlanList(plans: Array<QuotedPlanVm>) {
      if (_isNil(filters.planList) || filters.planList === 'all') return plans

      return PlanLists.applyList(filters.planList, plans)
    }

    function byPlanHighlight(plans: Array<QuotedPlanVm>) {
      if (filters.planHighlights.length === 0) return plans

      return filters.planHighlights.reduce((acc: QuotedPlanVm[], curr: PlanHighlightFilter) => {
        return acc.filter(planHighlightFilters[curr])
      }, plans)
    }

    function byCarrier(plans: Array<QuotedPlanVm>) {
      if (filters.carriers.length === 0) return plans
      return plans.filter((x) => filters.carriers.includes(x.details.carrierFilterKey!))
    }

    function byBenefits(plans: Array<QuotedPlanVm>) {
      if (filters.benefits.length === 0) return plans
      return plans.filter((x) =>
        // @ts-ignore
        filters.benefits.some((f) => x.details.selectedBenefits![PlanBenefitType[f]])
      )
    }

    function byProviders(plans: Array<QuotedPlanVm>) {
      if (filters.providers.length === 0) return plans
      return plans.filter((x) =>
        filters.providers.some((f) =>
          x.providerCoverage.providers.some((p) => p.npi === f && p.inNetwork)
        )
      )
    }

    function byRxs(plans: Array<QuotedPlanVm>) {
      if (filters.rxs.length === 0) return plans
      return plans.filter((x) =>
        filters.rxs.some((f) =>
          x.drugCoverage.coverageSummaries.some((r) => r.ndc === f && r.isCovered)
        )
      )
    }

    function byPharmacyNetwork(plans: Array<QuotedPlanVm>) {
      if (_isNil(filters.pharmacyNetwork)) return plans

      let filtered = plans

      if (filters.pharmacyNetwork === PharmacyCoverageLevel.Standard) {
        filtered = filtered.filter((x) => x.pharmacyCoverage.inNetwork)
      }

      if (filters.pharmacyNetwork === PharmacyCoverageLevel.Preferred) {
        filtered = filtered.filter(QuoteUtils.hasPreferredPharmacy)
      }

      return filtered
    }

    function byPolicyTypes(plans: Array<QuotedPlanVm>) {
      if (filters.policyTypes.length === 0) return plans

      return plans.filter((x) =>
        filters.policyTypes.includes(QuoteUtils.mapPolicyType(x.policyType, x.snpType))
      )
    }

    return {
      filter
    }
  }

  static useSort() {
    const session = SessionStore.use()
    const { flag } = FeatureFlags.use()
    const noPlanData = flag('no-plan-data', false)
    const defaultSortConfig = flag<Record<string, QuoteSortOrder>>('quote-sort-defaults')
    const profile = ProfileStore.use()

    const currentSort = computed<QuoteSortOrder>(() => {
      if (session.quoteSortOrder === 'default') return defaultSort.value
      return session.quoteSortOrder
    })

    const defaultSort = computed<QuoteSortOrder>(() => {
      const type = session.planType ?? PlanType.MAPD
      const configDefault = defaultSortConfig.value[PlanType[type].toLowerCase()]
      if (type === PlanType.GAP) return configDefault
      return noPlanData.value && (session.planYear ?? 0) > dayjs().year() + 1
        ? 'total_yearly_costs'
        : type === PlanType.MAPD
        ? (profile.needsAssessment?.defaultSort as QuoteSortOrder) || configDefault
        : configDefault
    })

    const availableSorts = computed<Array<QuoteSortOrder>>(() => {
      const values = _isNil(session.planType) ? [] : sortsByPlanType[session.planType]
      return _without(values, defaultSort.value) as Array<QuoteSortOrder>
    })

    const { costElements } = QuoteUtils.use()

    type SortFn = (plans: Array<QuotedPlanVm>) => Array<QuotedPlanVm>

    type CostSort = {
      fn: Array<(x: QuotedPlanVm) => unknown>
      order: Array<'asc' | 'desc'>
    }

    function getCostSort(): CostSort {
      switch (profile.needsAssessment.costFactor) {
        case CostFactor.ZeroPremium:
          return {
            fn: [(x: QuotedPlanVm) => x.details.premium],
            order: ['asc']
          }
        case CostFactor.LimitOop:
          return {
            fn: [
              (x: QuotedPlanVm) =>
                (x.details.medicalDeductible ?? 0) + (x.details.drugDeductible ?? 0)
            ],
            order: ['asc']
          }
        case CostFactor.LimitCopay:
          return {
            fn: [
              (x: QuotedPlanVm) => {
                const dr =
                  x.details.providerBenefits?.doctorCopay?.amountType === PlanBenefitCostType.Copay
                    ? x.details.providerBenefits?.doctorCopay?.amount ?? 100
                    : 100
                const spec =
                  x.details.providerBenefits?.specialistCopay?.amountType ===
                  PlanBenefitCostType.Copay
                    ? x.details.providerBenefits?.specialistCopay?.amount ?? 100
                    : 100

                return (dr + spec) / 2
              }
            ],
            order: ['asc']
          }
        default:
          return { fn: [], order: [] }
      }
    }

    const sorts: Record<QuoteSortOrder, SortFn> = {
      ['premium']: (plans) =>
        _orderBy(plans, [(x) => x.details.premium, (x) => x.rawScore], ['asc', 'desc']),
      ['oop']: (plans) =>
        _orderBy(plans, [(x) => x.details.maxOutOfPocket, (x) => x.rawScore], ['asc', 'desc']),
      ['deductible']: (plans) =>
        _orderBy(
          plans,
          [(x) => x.details.medicalDeductible ?? x.details.drugDeductible, (x) => x.rawScore],
          ['asc', 'desc']
        ),
      ['docs_then_est_costs']: (plans) =>
        _orderBy(
          plans,
          [
            (x) => x.providerCoverage.inNetworkCount,
            (x) => x.rawScore,
            (x) => x.getYearlyEstCost(costElements.value),
            (x) => x.details.maxOutOfPocket
          ],
          ['desc', 'desc', 'asc', 'asc']
        ),

      ['healthpilot_rec']: (plans) =>
        _orderBy(
          plans,
          [(x) => x.rawScore, (x) => x.getYearlyEstCost(costElements.value), (x) => x.medicareId],
          ['desc', 'asc']
        ),
      ['total_yearly_costs']: (plans) =>
        _orderBy(
          plans,
          [
            (x) => x.getYearlyEstCost(costElements.value),
            (x) => x.rawScore,
            (x) => x.details.maxOutOfPocket
          ],
          ['asc', 'desc', 'asc']
        ),
      ['default']: (plans) => sorts[defaultSort.value](plans)
    }

    function sortBy(plans: Array<QuotedPlanVm>, by: QuoteSortOrder) {
      return sorts[by](plans)
    }

    return {
      sortBy,
      defaultSort,
      currentSort,
      availableSorts
    }
  }

  static use() {
    const { flag } = FeatureFlags.use()

    const costElements = computed(() => {
      return _uniq([...flag('quote-cost-elements', []).value])
    })

    return {
      costElements
    }
  }

  static mapPolicyType(policyType: PolicyType, snpType: SnpType) {
    switch (snpType) {
      case SnpType.DualEligible:
        return PolicyType.DSNP
      case SnpType.ChronicCare:
        return PolicyType.CSNP
      case SnpType.LongTermCare:
        return PolicyType.LSNP
      default:
        return policyType
    }
  }

  static hasPreferredPharmacy(quote: QuotedPlanVm) {
    const standardOnlyCarriers = ['UnitedHealthcare'] // for carriers that don't have a "preferred" coverage level but only "in-network" or "out-of-network"
    return (
      !!quote.pharmacyCoverage.preferred ||
      (standardOnlyCarriers.includes(quote.details.carrierFilterKey!) &&
        !!quote.pharmacyCoverage.inNetwork)
    )
  }
}
