import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import { SessionStore } from '~/stores/session'
import { PlanType } from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'

export class SetPlanTypeMapdNode extends ProcessingNodeBase {
  run(): Promise<void> {
    const session = SessionStore.use()
    const profile = ProfileStore.use()

    if (session.carrierFootprint?.length && session.availablePlanTypes.includes(PlanType.MAPD)) {
      session.planType = PlanType.MAPD
      profile.needsAssessment.planType = PlanType.MAPD
    } else {
      console.warn('No MAPD plans available')
      if (session.availablePlanTypes.includes(PlanType.PDP)) {
        console.log('Choosing PDP instead')
        session.planType = PlanType.PDP
        profile.needsAssessment.planType = PlanType.PDP
      } else {
        console.warn('No MAPD or PDP plans available')
      }
    }

    return Promise.resolve()
  }
}
