import {
  AccountClient,
  ApplicationsClient,
  AppSchemaClient,
  BeqClient,
  CsClient,
  DrugsClient,
  EnrollmentsClient,
  GeoClient,
  ImportClient,
  PharmacyClient,
  PlanCheckupClient,
  ProfilesClient,
  ProvidersClient,
  QuotingClient,
  SandboxClient,
  SessionClient,
  SiteClient
} from '~/generated/api-clients-generated'

export class PxApi {
  static use() {
    const { $$fetch } = FetchFactory.use()

    const http = () => ({
      fetch: (url: RequestInfo, options: any) => $$fetch().raw<any>(url, options)
    })

    const runtimeConfig = useRuntimeConfig()
    let baseUrl = runtimeConfig.public.pxApiBaseUrl

    if (process.server && baseUrl.includes('localhost')) {
      baseUrl = baseUrl.replace('localhost', '127.0.0.1')
    }

    const createAccountClient = () => new AccountClient(baseUrl, http())
    const createAppSchemaClient = () => new AppSchemaClient(baseUrl, http())
    const createDrugsClient = () => new DrugsClient(baseUrl, http())
    const createGeoClient = () => new GeoClient(baseUrl, http())
    const createPharmacyClient = () => new PharmacyClient(baseUrl, http())
    const createProfilesClient = () => new ProfilesClient(baseUrl, http())
    const createProvidersClient = () => new ProvidersClient(baseUrl, http())
    const createSessionClient = () => new SessionClient(baseUrl, http())
    const createSiteClient = () => new SiteClient(baseUrl, http())
    const createImportClient = () => new ImportClient(baseUrl, http())
    const createApplicationsClient = () => new ApplicationsClient(baseUrl, http())
    const createSandboxClient = () => new SandboxClient(baseUrl, http())
    const createBeqClient = () => new BeqClient(baseUrl, http())
    const createPlanCheckupClient = () => new PlanCheckupClient(baseUrl, http())
    const createCsClient = () => new CsClient(baseUrl, http())

    let engineBaseUrl = runtimeConfig.public.engineApiBaseUrl
    if (process.server && baseUrl.includes('localhost')) {
      engineBaseUrl = engineBaseUrl.replace('localhost', '127.0.0.1')
    }
    const createQuotingClient = () => new QuotingClient(engineBaseUrl, http())
    const createEnrollmentClient = () => new EnrollmentsClient(engineBaseUrl, http())

    return {
      createAccountClient,
      createAppSchemaClient,
      createDrugsClient,
      createGeoClient,
      createPharmacyClient,
      createProfilesClient,
      createProvidersClient,
      createSessionClient,
      createSiteClient,
      createQuotingClient,
      createApplicationsClient,
      createImportClient,
      createEnrollmentClient,
      createSandboxClient,
      createBeqClient,
      createPlanCheckupClient,
      createCsClient
    }
  }
}
