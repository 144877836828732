import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxt) => {
  const runtimeConfig = useRuntimeConfig()

  // Sentry.init({
  //   app: nuxt.vueApp,
  //   dsn: runtimeConfig.public.sentryDsn,
  //   environment: runtimeConfig.public.sentryEnv,
  //   logErrors: false,
  //   debug: false
  //   // replaysSessionSampleRate: parseFloat(runtimeConfig.public.sentryReplaySampleRate),
  //   // integrations: [
  //   //   Sentry.replayIntegration({
  //   //     minReplayDuration: 15000,
  //   //     maskAllText: false,
  //   //     maskAllInputs: false,
  //   //     blockAllMedia: false,
  //   //     networkDetailAllowUrls: [
  //   //       runtimeConfig.public.pxApiBaseUrl,
  //   //       runtimeConfig.public.engineApiBaseUrl
  //   //     ]
  //   //   })
  //   // ]
  // })
})
