import { SessionStore } from '~/stores/session'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return

  const { isMobile } = Breakpoints.use()

  if(!isMobile.value) {
    return
  }

  const { onExitIntent: onAppExitIntent, shouldSurvey: shouldSurveyApp }
    = ExitIntent.useTriggers('application', 'exit-intent-application')

  const { onExitIntent: onGlobalExitIntent, shouldSurvey: shouldSurveyGlobal }
    = ExitIntent.useTriggers('global', 'exit-intent-global')

  if(!!from.fullPath.match(/^\/apply\/.+/) && !to.fullPath.startsWith('/apply') && shouldSurveyApp.value) {
    console.log('APP EXIT INTENT', from.fullPath, to.fullPath)
    onAppExitIntent()
    return abortNavigation()
  }

  if((['/quotes', '/compare'].includes(from.fullPath) || from.fullPath.startsWith('/details/')) && ['/medicaid', '/browse/medicaid', '/your-adventure'].includes(to.fullPath) && shouldSurveyGlobal.value) {
    onGlobalExitIntent()
    return abortNavigation()
  }

})
