import { SiteStore } from '~/stores/site'
import type { IPageNode, WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import {
  SignInResult,
  SignUpResult,
  VerifyResult,
  WaypointAuth,
  WaypointResult
} from '~/generated/api-clients-generated'
import { ProfileStore } from '~/stores/profile'
import { SessionStore } from '~/stores/session'

export class Auth {
  static use() {
    const { auth: cookie, touch } = CookieJar.use()
    touch()

    const isAuthenticated = computed(() => !_isNil(cookie.value))

    async function signUp(soft: boolean = false, req: Record<string, string> | null = null) {
      await ProfileStore.createIfEmpty()

      const session = SessionStore.use()

      const { createAccountClient } = PxApi.use()
      const client = createAccountClient()

      const request = req ?? {
        firstName: session.authBag.firstName,
        lastName: session.authBag.lastName,
        registrationToken: session.authBag.registrationToken,
        profileId: session.profileId,
        identifier: session.authBag.identifier,
      }

      try {
        const response = soft
          ? await client.signUpSoft(request)
          : await client.signUp(request)

        if (response.result === SignUpResult.Success) {
          session.authBag.registrationToken = response.token
          session.authBag.showTcpaConsent = response.showTcpaConsent
        }

        return response
      } catch (ex) {
        return {
          result: SignUpResult.Error
        }
      }
    }

    async function signIn() {
      const session = SessionStore.use()

      const { createAccountClient } = PxApi.use()
      const client = createAccountClient()

      try {
        const response = await client.signIn({
          identifier: session.authBag.identifier
        })

        session.authBag.showTcpaConsent = response.showTcpaConsent
        return response
      } catch (ex) {
        return {
          result: SignInResult.Error
        }
      }
    }

    async function verify(code: string) {
      const session = SessionStore.use()

      const { createAccountClient } = PxApi.use()
      const client = createAccountClient()

      try {
        return await client.verify({
          code: code,
          identifier: session.authBag.identifier,
          registrationToken: session.authBag.registrationToken,
          profileId: session.profileId!,
          attribution: !_isEmpty(session.params) ? { params: session.params } : null
        })
      } catch (ex) {
        return VerifyResult.Error
      }
    }

    async function signOut() {
      console.log('SIGNING OUT')
      const { createAccountClient } = PxApi.use()
      const client = createAccountClient()

      await client.logOut()
    }

    async function signUpRedirect(
      returnTo: WaypointNode | Path | null = null,
      isWaypoint: boolean = false
    ) {
      const site = SiteStore.use()
      return authRedirect(site.journey.signUpPath!, returnTo, isWaypoint)
    }

    async function signInRedirect(
      returnTo: WaypointNode | Path | null = null,
      isWaypoint: boolean = false
    ) {
      const site = SiteStore.use()
      return authRedirect(site.journey.signInPath!, returnTo, isWaypoint)
    }

    async function authRedirect(
      to: string,
      returnTo: WaypointNode | Path | null,
      isWaypoint: boolean = false
    ) {
      const { push, replace } = AppRouter.use()
      const { path, route } = AppRoute.use()
      const { current } = Waypoint.use()

      const returnPath = typeof returnTo === 'string' ? returnTo : (returnTo as IPageNode)?.path

      const nextPath = route.query.next?.toString() || null
      const key = isWaypoint || !!nextPath ? 'next' : 'return_to'

      const location = {
        path: to,
        query: {
          [key]: returnPath ?? nextPath ?? (route.query.return_to?.toString() || null) ?? path.value
        }
      }

      if (current.value?.replace) {
        await replace(location)
      } else {
        await push(location)
      }
    }

    async function returnRedirect() {
      const site = SiteStore.use()
      const { replace } = AppRouter.use()
      const { route } = AppRoute.use()
      const { current } = Waypoint.use()
      const { getCourse } = WaypointAutoPilot.use(WaypointResult.Next)

      const path =
        (route.query.next?.toString() || null) ?? // from RedirectNode
        getCourse() ?? // Sitemap config
        (current.value?.path === site.journey.signInPath ? site.journey.accountHomePath : null) ?? // /account/sign-in -> /dashboard
        (route.query.return_to?.toString() || null) ?? // /account/sign-up -> origin page
        '/' // ¯\_(ツ)_/¯

      console.log(
        'RETURN REDIRECT',
        path,
        route.query.next?.toString() || null,
        (current.value?.getNext(WaypointResult.Next) as IPageNode)?.path,
        current.value
      )
      current.value?.path === site.journey.signInPath ? site.journey.accountHomePath : null

      await replace(path)
    }

    function routeGuard(to: string) {
      const { get } = Waypoint.use()
      const site = SiteStore.use()

      const waypoint = get(to)

      if (waypoint?.auth === WaypointAuth.Protected && !isAuthenticated.value) {
        if (normalizePath(to) !== site.journey.signInPath) {
          return navigateTo(site.journey.signInPath)
        }
      }

      if (waypoint?.auth === WaypointAuth.Guest && isAuthenticated.value) {
        if (normalizePath(to) !== site.journey.accountHomePath) {
          return navigateTo(site.journey.accountHomePath)
        }
      }
    }

    return {
      isAuthenticated,
      signUp,
      signIn,
      verify,
      signOut,
      signUpRedirect,
      signInRedirect,
      returnRedirect,
      routeGuard
    }
  }
}
