import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { PlanCheckupStore } from '~/stores/planCheckup'
import { PlanType } from '~/generated/api-clients-generated'

export class CurrentPlanIsPdpNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const checkupStore = PlanCheckupStore.use()

    return this.yesNo(
      !_isNil(checkupStore.currentCheckup?.currentPlanId) &&
        checkupStore.currentCheckup?.currentPlanType === PlanType.PDP
    )
  }
}
