import { normalizePath } from '~/utils/path'

export class AppRoute {
  static use() {
    const route = useRoute()

    const path = computed(() => normalizePath(route.path))

    const pathParams = computed(() => route?.params?.path)

    const isIndex = computed(() => path.value === '/')

    const token = computed(() => route.query.token?.toString())

    const journeyId = computed(() => parseInt(route.query.journey_id?.toString() || '') || null)

    const isHealthCheck = computed(() => route.query.hc?.toString() === 'healthcheck')

    const isStartPage = computed(
      () => isIndex.value || ['/call-first', '/your-drugs', '/your-doctors'].includes(path.value)
    )

    return {
      route,
      path,
      pathParams,
      isIndex,
      token,
      journeyId,
      isStartPage,
      isHealthCheck
    }
  }
}
