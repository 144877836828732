import { EngineStore } from '~/stores/engine'
import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export default class LimitedChoice {
  static use() {
    const engine = EngineStore.use()
    const { quotingConfig } = QuotingConfig.use()
    const globalAddEnabled = computed(() => quotingConfig.value.globalAdd)

    const { sortBy, defaultSort, currentSort } = QuoteUtils.useSort()

    const limitedChoiceView = computed(
      () => quotingConfig.value.limitedChoice === true && currentSort.value === defaultSort.value
    )

    const limitedChoiceSet = computed(() =>
      //@ts-ignore
      limitedChoiceView.value ? _take(sortBy(engine.availableQuotes, defaultSort.value), 2) : []
    )

    const filterLcs = (plans: QuotedPlanVm[]) =>
      plans.filter((x) => !limitedChoiceSet.value.some((lc) => lc.medicareId === x.medicareId))

    const titleText = computed(() => {
      if (globalAddEnabled.value) {
        return (engine.params.doctors?.length ?? 0) > 0 ||
          (engine.params.rxs?.length ?? 0) > 0 ||
          (engine.params.additionalBenefits?.length ?? 0) > 0
          ? 'Best Fit For You'
          : 'Recommended Plans For You'
      }

      switch (defaultSort.value) {
        case 'docs_then_est_costs':
          return 'Best Plans for Your Doctor Coverage'
        case 'total_yearly_costs':
          return 'Best Plans for Your Yearly Costs'
        default:
          return 'Healthpilot Recommendation'
      }
    })

    return {
      limitedChoiceSet,
      titleText,
      filterLcs
    }
  }
}
