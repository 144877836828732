import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { PlanCheckupStore } from '~/stores/planCheckup'

export class CurrentPlanKnownNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const checkupStore = PlanCheckupStore.use()
    return this.yesNo(!_isNil(checkupStore.currentCheckup?.currentPlanId))
  }
}
