import ProcessingNodeBase from '~/models/journey/ProcessingNodeBase'
import undefined from '~/components/page/undefined.vue'
import { SessionStore } from '~/stores/session'
import dayjs from 'dayjs'

export default class CheckupAepPreRunNode extends ProcessingNodeBase {
  async run(): Promise<void> {
    const session = SessionStore.use()

    session.planYear = dayjs().year() + 1
  }
}