import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import type { Path } from '~/types/util'
import { PlanCheckupStore } from '~/stores/planCheckup'

export class SamePlanRecommendedNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const checkupStore = PlanCheckupStore.use()

    return this.yesNo(
      checkupStore.currentCheckup?.currentPlanId === checkupStore.currentCheckup?.bestPlanId
    )
  }
}
